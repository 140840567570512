<template>
  <div>
    <self-table
      :type="'arrived'"
      :list="arrivedList"
      :isLoading="isArrivedLoading"
      :title="'已经到达自提点'"
    />
    <self-table
      :type="'to_arrive'"
      :list="comingList"
      :isLoading="isComingLoading"
      :title="'即将到达自提点'"
    />
    <self-table
      :type="'history'"
      :list="historyList"
      :isLoading="isHistoryLoading"
      :title="'历史运单'"
      @search="handleSearch"
    />
  </div>
</template>

<script>
import SelfTable from './components/selfTable.vue'

export default {
  components: {
    SelfTable,
  },
  data() {
    return {
      arrivedList: [],
      comingList: [],
      historyList: [],
      isArrivedLoading: false,
      isComingLoading: false,
      isHistoryLoading: false,
    }
  },
  created() {
    this.handleInitList()
  },
  methods: {
    async handleInitList(data) {
      try {
        const value = localStorage.getItem('userInfo')
        if (value) {
          // const userInfo = JSON.parse(value)
          // const agent_id = userInfo.id
          if (data) {
            this.isHistoryLoading = true
            const { data: data3 } = await this.$api.statisticPickupArrived()
            this.isHistoryLoading = false
            this.historyList = data3.info
          } else {
            this.isArrivedLoading = true
            this.isComingLoading = true
            this.isHistoryLoading = true
            const { data: data1 } = await this.$api.statisticPickupArrived()
            const { data: data2 } = await this.$api.statisticPickupToArrive()
            const { data: data3 } = await this.$api.statisticPickupHistory({
              page: 1,
              page_size: 10,
            })
            this.isArrivedLoading = false
            this.isComingLoading = false
            this.isHistoryLoading = false
            this.arrivedList = [{
              total_packages_amount: data1.total_packages_amount,
              total_shipments_amount: data1.total_shipments_amount,
            }]
            this.comingList = [{
              total_packages_amount: data2.total_packages_amount,
              total_shipments_amount: data2.total_shipments_amount,
            }]
            this.historyList = data3.month_info
          }
        }
      } catch (error) {
        this.isArrivedLoading = false
        this.isComingLoading = false
        this.isHistoryLoading = false
        console.log('error', error)
      }
    },
    handleSearch(msg) {
      this.handleInitList(msg)
    },
  },
}
</script>

  <style lang="scss" scoped>

  </style>
