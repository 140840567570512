<template>
  <b-card>
    <div
      v-if="isLoading"
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner
        type="grow"
        variant="warning"
        class="mr-1"
      />
      <span class="loading-text">Loading...</span>
    </div>
    <div v-else>
      <div class="border-b mb-2 d-flex justify-content-between align-items-center">
        <span class="card-title">{{ title }}</span>
        <div
          v-if="type === 'history'"
          style="width:24%"
          class="d-flex justify-content-end align-items-center"
        >
          <flat-pickr
            v-model="rangeDate"
            class="form-control"
            placeholder="开始时间 ~ 结束时间"
            :config="{ mode: 'range'}"
            @on-change="handleRangeChange"
          />
          <span
            class="search-text"
            @click="handleSerach"
          >搜索</span>
        </div>
      </div>
      <span
        v-if="list.length === 0"
        class="no-data"
      >暂无数据</span>
      <b-table
        v-else
        responsive
        bordered
        :items="list"
        :fields="fields[type]"
      >
        <template #cell(price)="data">
          <span>AU${{ data.item.price ? Number(data.item.price).toFixed(2) : '0.00' }}</span>
        </template>
        <template #cell(action)="data">
          <span
            class="watch"
            @click="handleWatch(data.item)"
          >查看</span>
        </template>
      </b-table>
      <!-- <b-row class="mt-2">
        <b-col>
          <b-form-group
            cols="2"
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col cols="10">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            class="my-0"
          />
        </b-col>
      </b-row> -->
    </div>
  </b-card>
</template>

<script>
import {
  BTable, BCard,
  // BPagination, BFormGroup, BFormSelect, BRow, BCol,
  BSpinner,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import dayjs from 'dayjs'

export default {
  components: {
    BTable,
    BCard,
    // BPagination,
    // BFormGroup,
    // BFormSelect,
    // BRow,
    // BCol,
    flatPickr,
    BSpinner,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
      require: true,
    },
    title: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'arrived',
    },
  },
  data() {
    return {
      fields: {
        arrived: [
          {
            key: 'total_shipments_amount', label: '合计运单数量', sortable: true,
          },
          {
            key: 'total_packages_amount', label: '合计包裹数量', sortable: true,
          },
          {
            key: 'action', label: '操作',
          },
        ],
        to_arrive: [
          {
            key: 'total_shipments_amount', label: '合计运单数量', sortable: true,
          },
          {
            key: 'total_packages_amount', label: '合计包裹数量', sortable: true,
          },
          {
            key: 'action', label: '操作',
          },
        ],
        history: [
          {
            key: 'year_month', label: '日期', sortable: true,
          },
          {
            key: 'total_shipments_amount', label: '合计运单数量', sortable: true,
          },
          {
            key: 'total_packages_amount', label: '合计包裹数量',
          },
          {
            key: 'total_chargeable_weight', label: '合计计费数量',
          },
          // {
          //   key: 'price', label: '预计营收',
          // },
          {
            key: 'action', label: '操作',
          },
        ],
      },
      currentPage: 1,
      totalRows: 10,
      perPage: 10,
      pageOptions: [5, 10, 15, 20],
      rangeDate: '',
      searchObj: {},
    }
  },
  methods: {
    handleWatch(item) {
      if (this.type === 'history') {
        this.$router.push(`/selfstatistics/detail/${item.year_month}`)
      } else {
        this.$router.push(`/selfstatistics/detail/${this.type}`)
      }
    },
    handleRangeChange(e) {
      this.searchObj = {
        start_date: dayjs(e[0]).format('YYYY-MM-DD'),
        end_date: dayjs(e[1]).format('YYYY-MM-DD'),
      }
    },
    handleSerach() {
      this.$emit('search', this.searchObj)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
 .watch{
    cursor: pointer;
    font-size: 18px;
    color: #FFAF2A;
  }
  .loading-text{
    font-size: 26px;
  }
  .search-text{
    min-width:40px;
    margin-left:10px;
    color: #FFAF2A;
    cursor: pointer;
  }
  .no-data{
    font-size: 24px;
    display: block;
    text-align: center;
  }
</style>
